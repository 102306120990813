import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import LinkedIn from 'svgs/linkedin.svg'
import { ButtonLink, SecondaryButtonLink } from 'components/styled/button'
import ArrowIcon from '../svgs/arrow_top_right.svg'
import axios from 'axios'
import { config } from '../config'
import { formatName, getImageUrl, getThumbnailForVideo } from '../Utils'
import { DEFAULT_PROFILE_IMAGE_URL, getOptions } from '../data/dictionary'
import CarretDown from '../svgs/caret_down.svg'
import RenderHtml from '../components/shared/renderHtml'
import queryString from 'query-string'
import SecuredRoute from '../hoc/SecuredRoute'


const Container = styled.div`
  min-height: 100vh;
  padding-right: 100px;
  padding-left: 100px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1520px;

  .video {
    width: 268px;
    height: 150px;
  }

  .text-primary-blue {
    color: #0656F1
  }

  img.author {
    margin-right: 20px;
    height: 64px;
    width: 64px;
    border-radius: 100%;
    object-fit: cover;
  }

  .lh-1 {
    line-height: 1;
  }

  .social {
    height: 20px;

    svg {
      margin-left: .5rem;

      path {
        transition: all 0.3s;
        fill: var(--primary);
      }

      &:hover {
        path {
          fill: var(--secondary);
        }
      }
    }
  }


  button {
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;

    > svg {
      margin-left: calc(var(--spacing) * 3.5);
    }
  }

  a {
    font-weight: 500;

    > svg {
      margin-left: calc(var(--spacing) * 3.5);
    }
  }

  .d-lg-none {
    display: none;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .py-3rem {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-3rem {
    padding-top: 3rem;
  }

  .pb-3rem {
    padding-bottom: 3rem;
  }

  .element + .element {
    border-top: 1px solid #bababa;
  }

  .fs-24px {
    font-size: 24px;
  }

  #filterDropdown {
    border: 2px solid #BABABA;
    border-radius: 15px;
    overflow: hidden;
  }

  .mb-12px {
    margin-bottom: 12px;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  .mt-20px {
    margin-top: 20px;
  }

  .pb-100px {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .w-tablet-100 {
      width: 100%;
    }

    .mt-tablet-20px {
      margin-top: 20px;
    }

    .pb-100px {
      padding-bottom: 30px;
    }

    .pt-3rem {
      padding-top: 1.5rem;
    }

    .element {
      border-top: 1px solid #bababa;
    }

    .border-top:not(.border-top ~ .border-top) {
      border-top: 1px solid #bababa;
    }

    .py-3rem {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .pb-3rem {
      padding-bottom: 1.5rem;
    }

    .video {
      width: 100%;
      height: 100%;
      max-height: 200px;
    }

    .d-tablet-none {
      display: none;
    }

    .d-lg-none {
      display: block;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    .fs-24px {
      font-size: 18px;
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
`
const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 0 0%;

  a, a > h6 {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }

  &.mw-771px p {
    max-width: 771px !important;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`
const ColumnLgAuto = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
  max-width: auto;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const ColumnFour = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 25%;
  max-width: 25%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledLabel = styled.label`
  font-weight: 500;
  margin: 0 4px 4px 4px;
  display: flex;
  align-items: center;
`


const ChallengeList = ({ pageContext }) => {

    const { page } = pageContext
    const [challenges, setChallenges] = useState([])

    const [user, setUser] = useState({})

    const [filter, setFilter] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [onlyMine, setOnlyMine] = useState(false)

    useEffect(async () => {
        try {
            let response = (await axios.get(`${config.API_BASE_URL}/challenges`)).data
            setChallenges(response)
        } catch (e) {
        }

        const { onlyMine: mine } = queryString.parse(window.location.search)
        setOnlyMine(!!mine)

    }, [])

    useEffect(async () => {
        try {
            const token = window.localStorage.getItem('token')
            let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
            setUser(user)
        } catch (e) {
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('token')
            window.location.href = '/'
        }
    }, [])

    useEffect(() => {
        setCurrentPage(1)
    }, [filter])

    let isOpen = false

    let toggle = (e) => {
        isOpen = !isOpen
        if (isOpen) {
            document.getElementById('filterDropdown').classList.add('active')
        } else {
            document.getElementById('filterDropdown').classList.remove('active')
        }
    }

    const _challenges = challenges.filter(challenge => {
        return (!onlyMine || challenge.user && challenge.user.id === user.id) && !filter || (challenge.data.target_model_1 && challenge.data.target_model_1.value === filter) || (challenge.data.target_model_2 && challenge.data.target_model_2.value === filter)
    })

    return (
        <SecuredRoute>
            <Layout title={`BuildUp: Challenge List`} hideTitlePrefix={true}>
                <div className='page'>
                    <Container className='container'>
                        {user && user.role !== 'ROLE_STUDENT' &&
                            <Row className='pt-3rem pb-100px border-bottom'>
                                <Column className='pr-0 pl-0 mw-771px'>
                                    <RenderHtml html={page.content} />
                                    <ButtonLink href={'/challenge-submission'} className='w-tablet-100'>
                                        + Add a Challenge
                                    </ButtonLink>
                                </Column>
                                <ColumnFour className='d-flex flex-column pl-0'>
                                    <StaticImage
                                        src='../images/mentorship-freamwork.png'
                                        alt='workspace'
                                        layout='constrained'
                                        className='mt-auto mb-auto d-tablet-none'
                                        placeholder='tracedSVG'
                                    />

                                    <SecondaryButtonLink href='/Mentorship at BuildUp.pdf' target='_blank'
                                                         className='mt-auto w-tablet-100 mt-tablet-20px'>
                                        Download PDF
                                    </SecondaryButtonLink>
                                </ColumnFour>
                            </Row>
                        }
                        <Row className='py-3rem'>
                            <Column className='pr-0 pl-0'>
                                <h5 className='mb-0 mt-0'>Challenges available for adoption</h5>
                                <p className='mt-0 mb-0'>Please review and apply for the challenge that most interests
                                    you.</p>
                            </Column>
                            <ColumnFour className='position-relative'>
                                <StyledLabel>Filter</StyledLabel>
                                <div className='position-desktop-absolute w-100'>
                                    <div className='dropdown' id='filterDropdown' onClick={() => toggle(Event)}>
                                        <span>{filter || 'All'} <CarretDown></CarretDown></span>
                                        <ul className='dropdownMenu bg-white'>
                                            <li onClick={() => {
                                                document.getElementById('filterDropdown').classList.remove('active')
                                                setFilter(null)
                                            }}>All
                                            </li>
                                            {getOptions('target_models').map(o => {
                                                return <li onClick={() => {
                                                    document.getElementById('filterDropdown').classList.remove('active')
                                                    setFilter(o.value)
                                                }} id={o.value}>{o.label}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </ColumnFour>
                        </Row>
                        {_challenges.length === 0 && <span>No challenges found.</span>}
                        {_challenges.map(challenge => {
                            return <Row className='element py-3rem'>
                                <Column className='d-lg-none'>
                                    <a href={`/challenge/${challenge.id}`}><h6 className='mt-0 mb-0'>
                                        {challenge.data.title}
                                    </h6>
                                    </a>
                                    <p className='text-muted mt-0'>{challenge.data.category.label}</p>
                                </Column>
                                {challenge.data.video_embed_url && !challenge.data.video_url &&
                                    <ColumnLgAuto className='pl-0'>
                                        <div className='video'>
                                            <a href={`/challenge/${challenge.id}`}>
                                                <img alt={'Challenge video'}
                                                     src={getThumbnailForVideo(challenge.data.video_embed_url)}
                                                     className={'w-100 h-100'} /></a>
                                        </div>
                                    </ColumnLgAuto>
                                }
                                {challenge.data.video_url && <ColumnLgAuto className='pl-0'>
                                    <div className='video'>
                                        <a href={`/challenge/${challenge.id}`}>

                                            <video width='360' className={'w-100 h-100'}>
                                                <source src={`${getImageUrl(challenge.data.video_url)}#t=0.1`}
                                                        type='video/mp4' />
                                            </video>
                                        </a>
                                    </div>
                                </ColumnLgAuto>
                                }
                                <Column className={`${challenge.image ? '' : 'pl-0'}`}>

                                    <a href={`/challenge/${challenge.id}`}>
                                        <h6 className='mt-0 mb-0 d-tablet-none'>{challenge.data.title}
                                        </h6>
                                    </a>

                                    <p className='text-muted mt-0 d-tablet-none'>{challenge.data.category.label}</p>
                                    <p>{challenge.data.problem_summary.slice(0, 349)}... <a
                                        href={`/challenge/${challenge.id}`}
                                        className='text-primary-blue'>Read
                                        more</a></p>
                                    {challenge.user && challenge.user.id === user.id && onlyMine &&
                                        <a href={`/challenge-submission?id=${challenge.id}`}
                                           className='text-primary-blue'>Edit</a>
                                    }
                                </Column>
                                <ColumnFour className='pr-0'>
                                    <div className='d-flex flex-column px-0'>
                                        <div className='d-flex'>
                                            <img
                                                src={getImageUrl(challenge.mentor.image, DEFAULT_PROFILE_IMAGE_URL)}
                                                className='author' alt='' />
                                            <div className='my-mobile-10px'>
                                                <p className='title mb-0 mt-0 lh-1'>{formatName(challenge.mentor)}</p>
                                                <p className='text-muted mt-1 mb-0 lh-1 d-flex align-items-center'>mentor
                                                    {challenge.mentor.linkedin &&
                                                        <a href={challenge.mentor.linkedin} target='_blank'
                                                           className='social'><LinkedIn /></a>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <ButtonLink href={`/challenge-apply?id=${challenge.id}`}
                                                    className='w-100 mt-20px'>Apply <ArrowIcon /></ButtonLink>
                                        {challenge.mentor.calendly &&
                                            <SecondaryButtonLink className='w-100 mt-20px'
                                                                 href={challenge.mentor.calendly} target='_blank'>Contact
                                                Mentor</SecondaryButtonLink>}
                                    </div>
                                </ColumnFour>
                            </Row>
                        })}
                    </Container>
                </div>
            </Layout>
        </SecuredRoute>
    )
}

export default ChallengeList
